import { fetch } from './api.service';

export const getUser = id => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`/femployee/${id}`)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const getUserModules = (employeeId, lang) => {
  return new Promise((resolve, reject) => {
    fetch
      .get(`/femployee/${employeeId}/quiz/${lang}`)
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};

export const saveUserResult = (userId, modules, wrongQuestions) => {
  const resultModules = modules.map(
    ({ moduleId, moduleName, correctAnswers, totalQuestions }) => ({
      moduleId,
      moduleName,
      correctAnswers,
      totalQuestions,
    })
  );
  return new Promise((resolve, reject) => {
    fetch
      .post(`/femployee/result`, {
        employeeId: userId,
        modules: resultModules,
        wrongQuestions,
        task: 'clean code',
        attempt: 'first attempt',
      })
      .then(data => {
        resolve(data);
      })
      .catch(error => {
        console.log(error);
        reject(error.errors);
      });
  });
};
