exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!../../assets/css/bootstrap.min.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!../../assets/css/font-awesome.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!../../assets/css/corporate.css"), "");
exports.i(require("-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/video-react/dist/video-react.css"), "");
var urlEscape = require("../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/images/standbild_ohne_text.jpg"));

// Module
exports.push([module.id, ":root {\n  --default-font: Helvetica, Sans-Serif;\n}\nbody {\n  background-color: #f2f2f2;\n}\n#root {\n  font-family: var(--default-font);\n  width: 100%;\n  height: 100%;\n}\n.login-img {\n  width: 100%;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL___0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n  background-color: rgba(19, 19, 19, 0.5);\n  /*dim the background*/\n}\n.overlay-img {\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 10;\n  background-color: rgba(19, 19, 19, 0.5);\n  /*dim the background*/\n}\n.login-wrapper {\n  background-color: unset;\n}\n@media (min-width: 769px) and (max-width: 1200px) {\n  .login-wrapper .login-container {\n    width: 40%;\n  }\n}\n.truncate {\n  width: 250px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.ax-container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);

