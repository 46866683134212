import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
// import { setInitialUserData } from '../../actions/userActions';
// import { setInitialModulesData } from '../../actions/modulesActions';
import { logout } from '../../services/auth.service';
import { routeEndpoint } from '../../services/api.service';
import './results.less';

class Results extends Component {
  constructor(props) {
    super(props);
    this.saveResults = this.saveResults.bind(this);
  }

  saveResults() {
    const { history } = this.props;
    // dispatch(setInitialUserData());
    // dispatch(setInitialModulesData());
    logout();
    history.push(routeEndpoint);
  }

  render() {
    const { userData, resultModules } = this.props;
    return (
      <div>
        <div className="copyright sm-text-center ">
          <h2 className="ax-title-style">
            <FormattedMessage
              id="results.evaluation"
              defaultMessage="Auswertung"
            />
          </h2>
        </div>
        <div className="container">
          <div className="row m-t-50 ">
            <div className="col-md-3">
              <p className="ax-title">
                <FormattedMessage
                  id="results.foreignworker"
                  defaultMessage="Fremdarbeiter"
                />
                :
              </p>
            </div>
            <div className="col-md-3">
              <p className="ax-title-stylee">
                {userData.name} {userData.surname}
              </p>
            </div>
          </div>
          <div className="row m-t-40">
            <div className="col-md-3">
              <p className="ax-title">
                <FormattedMessage id="results.module" defaultMessage="Modul" />:
              </p>
            </div>

            <div className="col-md-9">
              {resultModules.map(module => {
                return (
                  <div className="ax-box-row" key={module.moduleName}>
                    <p className="ax-box-column ax-title-stylee ax-box-title">
                      {module.moduleName}
                    </p>

                    <span className="ax-box-column ax-title-stylee">
                      {Math.floor(
                        (100 / module.totalQuestions) * module.correctAnswers
                      )}
                      %
                    </span>

                    <p className="ax-box-column ax-title-stylee">
                      <FormattedMessage
                        id="results.outof"
                        defaultMessage="{num} von {numb} Fragen korrekt"
                        values={{
                          num: module.correctAnswers,
                          numb: module.totalQuestions,
                        }}
                      />
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row m-t-50">
            <div className="col-md-6">
              <p className="ax-title">
                <FormattedMessage
                  id="results.important"
                  defaultMessage="Wichtig"
                />
              </p>

              <p className="ax-title-stylee">
                <FormattedMessage
                  id="results.importanttext"
                  defaultMessage="- Bitte geben Sie das Tablet wieder bei der FreKo ab"
                />
              </p>
              <p className="ax-title-stylee">
                <FormattedMessage
                  id="results.importanttext1"
                  defaultMessage="- Bitte befolgen Sie die Anweisungen des Fachpersonals"
                />
              </p>
            </div>
          </div>
        </div>
        <div className="row float-right m-r-50">
          <button
            onClick={this.saveResults}
            className="button btn btn-danger btn-cons m-t-50 "
            type="submit"
          >
            <FormattedMessage
              id="results.complete"
              defaultMessage="Abschliessen"
            />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user, modules }) => ({
  userData: user.userData,
  resultModules: modules.resultModules,
  wrongQuestions: modules.wrongQuestions,
});

export default connect(mapStateToProps)(Results);
