const decodingChar = char => {
  const charset = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  const position = charset.indexOf(char);
  const decoding = position - 9;
  const decoded = charset[decoding];

  return decoded;
};

export default decodingChar;
