import React, { Component } from 'react';
import Loader from '../../utils/loader';
import { login } from '../../services/auth.service';
import { routeEndpoint } from '../../services/api.service';
import logo from '../../../assets/images/logo-axpo.png';
import './login.less';

class LogIn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      submitted: false,
      loading: false,
      error: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const trimUsername = username.trim();
    const trimPassword = password.trim();
    // stop here if form is invalid
    if (!(trimUsername && trimPassword)) {
      return;
    }

    this.setState({ loading: true });
    login(trimUsername, trimPassword).then(
      () => {
        const { history } = this.props;
        history.push(routeEndpoint);
      },
      error => this.setState({ error, loading: false })
    );
  }

  render() {
    const { username, password, submitted, loading, error } = this.state;
    return (
      <div className="ax-login-container text-center">
        <div className="ax-login-logo">
          <img alt="Axpo logo" src={logo} />
        </div>
        <form
          id="form-login"
          className="ax-login-form"
          name="form"
          onSubmit={this.handleSubmit}
          noValidate
        >
          <div className="login-screen" />
          {/* <!-- START Form Control--> */}
          {error && !loading && (
            <div className="alert alert-danger">{error}</div>
          )}
          <div
            className={`form-group form-group-default ${
              submitted && !username ? ' has-error' : ''
            }`}
          >
            <label htmlFor="username">
              Benutzername
              <div className="controls">
                <input
                  id="username"
                  type="text"
                  name="username"
                  placeholder="Benutzername"
                  className="form-control"
                  value={username}
                  autoComplete="off"
                  onChange={this.handleChange}
                  required
                />
              </div>
            </label>
          </div>
          {submitted && !username && (
            <p id="username-error" className="error">
              Benutzername ist erforderlich.
            </p>
          )}
          {/* <!-- END Form Control--> */}
          {/* <!-- START Form Control--> */}
          <div
            className={`form-group form-group-default ${
              submitted && !password ? ' has-error' : ''
            }`}
          >
            <label htmlFor="password">
              Passwort
              <div className="controls">
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Passwort"
                  value={password}
                  onChange={this.handleChange}
                  autoComplete="off"
                  required
                />
              </div>
            </label>
          </div>
          {submitted && !password && (
            <p id="username-error" className="error">
              Passwort wird benötigt.
            </p>
          )}
          {/* <!-- START Form Control--> */}
          {loading && (
            <div>
              <Loader />
            </div>
          )}
          {/* <!-- END Form Control--> */}
          <button
            className="btn btn-danger btn-cons m-t-10 btn-color"
            disabled={loading}
            type="submit"
          >
            Anmelden
          </button>
        </form>
      </div>
    );
  }
}

export default LogIn;
