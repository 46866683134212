import {
  SET_USER_DATA,
  SET_USER_LANGUAGE,
  SET_USER_DATA_INITIAL,
} from './actionTypes';
import { apiStart, apiError } from './api';
import { getUser } from '../services/user.service';
import { getUserData } from '../services/auth.service';

export const setUserData = data => ({
  type: SET_USER_DATA,
  payload: data,
});

export const setUserLanguage = lang => ({
  type: SET_USER_LANGUAGE,
  payload: lang,
});

export const fetchUserData = () => dispatch => {
  const { id } = getUserData();
  dispatch(apiStart());
  return getUser(id)
    .then(data => {
      dispatch(setUserData({ ...data, id }));
    })
    .catch(error => dispatch(apiError(error)));
};

export const setInitialUserData = () => ({
  type: SET_USER_DATA_INITIAL,
});
