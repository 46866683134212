/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import swal from 'sweetalert';
import Video from '../../components/video/video';
import Questions from '../../components/questions/questions';
import AnswerConfirmation from '../../components/answerConfirmation/answerConfirmation';
import './quiz.less';
import {
  setResultModules,
  setCurrentModule,
  setUserQuestions,
  setCurrentResultModule,
  setCurrentQuestion,
  setCorrectQuestions,
  setCurrentQuestionTxt,
  setWrongQuestions,
} from '../../actions/modulesActions';
import { saveUserResult } from '../../services/user.service';
import { routeEndpoint } from '../../services/api.service';
import decodingChar from '../../utils/decodingChar';

class Quiz extends Component {
  constructor() {
    super();
    this.state = {
      showPopup: false,
      isAnswerCorrect: null,
      questionAnswer: null,
      videoEnded: false,
      correctAnswers: 0,
      currentModule: [],
      currentObject: {},
      currentQuestion: 0,
      results: [],
      totalQuestions: 0,
    };

    window.onbeforeunload = window.history.pushState(
      null,
      null,
      window.location.href
    );

    window.onpopstate = () => {
      window.history.go(1);
    };

    window.onbeforeunload = () => {
      const msg = '';
      return msg;
    };

    this.getNextItem = this.getNextItem.bind(this);
    this.changeModule = this.changeModule.bind(this);
  }

  componentWillMount() {
    // console.log(this.props);
    const {
      modules,
      currentModule,
      dispatch,
      currentResultModule,
    } = this.props;
    this.setState({
      currentModule,
    });
  }

  async componentDidMount() {
    const {
      dispatch,
      questions,
      currentModule,
      currentQuestion,
      currenQuestionTxt,
    } = this.props;

    const onlyQuestions = [];
    currentModule.questions.forEach(item => {
      const keyName = Object.keys(item);
      // eslint-disable-next-line no-unused-expressions
      keyName[0] === 'question' ? onlyQuestions.push(keyName[0]) : null;
    });
    await this.setState({
      totalQuestions: onlyQuestions.length,
      currentObject: currenQuestionTxt,
    });
    if (typeof currenQuestionTxt === 'undefined') {
      this.changeModule();
    }
  }

  showAnswerConfirmation() {
    const { isAnswerCorrect, currentObject } = this.state;
    const checkAnswer = decodingChar(currentObject.correctAnswer);
    let correctAnswer;

    if (currentObject.answers) {
      currentObject.answers.map(answer => {
        if (checkAnswer === Object.keys(answer)[0]) {
          correctAnswer = answer[checkAnswer];
        }
      });
    }

    if (isAnswerCorrect === null) {
      this.getNextItem(false);
    } else if (isAnswerCorrect === true) {
      swal({
        title: 'Korrekte Antwort!',
        icon: 'success',
        closeOnClickOutside: false,
        className: 'swal-success-title',
        button: {
          text: 'OK',
        },
      }).then(() => {
        this.getNextItem(false);
      });
    } else if (isAnswerCorrect === false) {
      swal({
        title: 'Nicht korrekt!',
        text: `Die korrekte Antwort ist: \n${correctAnswer}`,
        icon: 'error',
        closeOnClickOutside: false,
        button: {
          text: 'OK',
        },
      }).then(() => {
        this.getNextItem(false);
      });
    }
  }

  async getNextItem(type) {
    const {
      questions,
      dispatch,
      correctQuestions,
      currentQuestion,
    } = this.props;
    //console.log('CURRENT QUESTION--------', currentQuestion);
    const { currentObject, questionAnswer } = this.state;
    const lastQuestions = Object.keys(currentObject);
    if (lastQuestions[0] === 'question') {
      const checkAnswer = decodingChar(currentObject.correctAnswer);
      if (questionAnswer === checkAnswer) {
        this.setState({ correctAnswers: ++this.state.correctAnswers });
        let cq = await correctQuestions;
        await cq++;
        dispatch(setCorrectQuestions(cq));
      } else {
        const oldw = this.props.wrongQuestions;
        oldw.push({
          text: currentObject.question,
          questionKey: currentObject.questionKey,
        });
        dispatch(setWrongQuestions(oldw));
      }
    }

    const nextItem = questions.shift();
    await dispatch(setUserQuestions(questions));
    await dispatch(setCurrentQuestionTxt(nextItem));

    if (typeof nextItem !== 'undefined') {
      const checkIsQuestion = Object.keys(nextItem);
      if (checkIsQuestion[0] === 'question') {
        let cq = this.props.currentQuestion;
        cq++;
        dispatch(setCurrentQuestion(cq));
        this.setState({
          videoEnded: false,
          questionAnswer: null,
          currentQuestion: ++this.state.currentQuestion,
        });
      } else if (checkIsQuestion[0] === 'link') {
        this.setState({
          videoEnded: false,
        });
      }
      if (type !== true) {
        this.setState({
          isAnswerCorrect: null,
          questionAnswer: null,
          currentObject: nextItem,
        });
      }
    } else {
      this.changeModule();
    }
  }

  setAnswer = selectedAnswer => {
    const { currentObject } = this.state;
    const checkAnswer = decodingChar(currentObject.correctAnswer);

    if (selectedAnswer === checkAnswer) {
      this.setState({ isAnswerCorrect: true });
    } else {
      this.setState({ isAnswerCorrect: false });
    }

    this.setState({
      videoEnded: true,
      questionAnswer: selectedAnswer,
    });
  };

  hasVideoEnded = decision => {
    const { videoEnded } = this.state;
    this.setState({
      videoEnded: decision === videoEnded ? false : decision,
    });
  };

  changeModule() {
    const {
      currentModule,
      totalQuestions,
      correctAnswers,
      results,
    } = this.state;
    const {
      history,
      dispatch,
      userData,
      modules,
      currentResultModule,
      correctQuestions,
    } = this.props;
    const nextModule = modules.shift();
    dispatch(setCurrentModule(nextModule));
    if (typeof nextModule !== 'undefined') {
      const registerModule = {
        moduleId: currentModule.moduleId,
        moduleName: currentModule.moduleName,
        totalQuestions,
        correctAnswers: correctQuestions,
      };
      currentResultModule.push(registerModule);
      dispatch(setCurrentResultModule(currentResultModule));
      const onlyQuestions = [];
      nextModule.questions.forEach(item => {
        const keyName = Object.keys(item);
        keyName[0] === 'question' ? onlyQuestions.push(keyName[0]) : null;
      });
      this.setState({
        results,
        correctAnswers: 0,
        totalQuestions: onlyQuestions.length,
        questionAnswer: null,
        currentQuestion: 0,
        currentModule: nextModule,
      });
      dispatch(setCorrectQuestions(0));
      dispatch(setCurrentQuestion(0));
      dispatch(setUserQuestions(nextModule.questions));
      this.getNextItem(false);
    } else {
      const { wrongQuestions } = this.props;
      const registerModule = {
        moduleId: currentModule.moduleId,
        moduleName: currentModule.moduleName,
        totalQuestions,
        correctAnswers: correctQuestions,
      };
      currentResultModule.push(registerModule);
      dispatch(setCurrentResultModule(currentResultModule));
      dispatch(setResultModules(currentResultModule));
      saveUserResult(userData.id, currentResultModule, wrongQuestions).then(
        () => {
          history.push(`${routeEndpoint}results`);
        }
      );
    }
  }

  render() {
    const { videoEnded, questionAnswer, currentObject } = this.state;
    const { currentQuestion } = this.props;

    return (
      <div className="ax-wrapper">
        <div
          className={`${currentObject && currentObject.link ? '' : 'hidden'}`}
        >
          <Video
            videoLink={currentObject.link}
            hasVideoEnded={this.hasVideoEnded}
          />
        </div>

        {currentObject && currentObject.question ? (
          <React.Fragment>
            {/* {showPopup === true ? (
              <AnswerConfirmation
                answerIsCorrect={isAnswerCorrect}
                selectedAnswer={questionAnswer}
                correctAnswer="corAms"
                confirmationDone={() => this.getNextItem(false)}
              />
            ) : (
              ''
            )} */}
            <div>{questionAnswer}</div>
            <Questions
              question={currentObject.question}
              answers={currentObject.answers}
              setAnswer={this.setAnswer}
              selectedAnswer={questionAnswer}
              currentQuestion={currentQuestion}
            />
          </React.Fragment>
        ) : null}

        <div
          className={`button-wrapper ${
            currentObject.link ? 'video' : 'default'
          }`}
        >
          <button
            disabled={!videoEnded}
            onClick={() => this.showAnswerConfirmation()}
            className={`ax-button m-t-5 float-right
            ${videoEnded ? 'show' : 'hidden'}`}
            type="submit"
          >
            <FormattedMessage id="quiz.continue" defaultMessage="Weiter" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ user, modules }) => ({
  userData: user.userData,
  modules: modules.modules,
  questions: modules.questions,
  currentModule: modules.currentModule,
  currentResultModule: modules.currentResultModule,
  currentQuestion: modules.currentQuestion,
  correctQuestions: modules.correctQuestions,
  currenQuestionTxt: modules.currenQuestionTxt,
  wrongQuestions: modules.wrongQuestions,
});

export default connect(mapStateToProps)(Quiz);
