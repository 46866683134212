import React from 'react';
import './answerConfirmation.less';

const AnswerConfirmation = ({
  answerIsCorrect,
  selectedAnswer,
  correctAnswer,
  confirmationDone,
}) => {
  return (
    <div className="answer-confirmation-wrapper">
      <div
        className={`answer-confirmation-container ${
          answerIsCorrect ? 'answer-correct' : 'answer-incorrect'
        }`}
      >
        {answerIsCorrect ? (
          <div>
            <h2>Korrekte Antwort!</h2>
            <p>{selectedAnswer}</p>
          </div>
        ) : (
          <div>
            <h2>Nicht korrekt!</h2>
            <p>Antwort:</p>
            <p>{selectedAnswer}</p>

            <p>Korrekte Antwort:</p>
            <p>{correctAnswer}</p>
          </div>
        )}
        <button onClick={confirmationDone} type="submit">
          DONE
        </button>
      </div>
    </div>
  );
};

export default AnswerConfirmation;
