export const FETCH_USER_DATA = 'FETCH_USER_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_LANGUAGE = 'SET_USER_LANGUAGE';

export const SET_USER_MODULES = 'SET_USER_MODULES';
export const SET_USER_DATA_INITIAL = 'SET_USER_DATA_INITIAL';

export const SET_CURRENT_MODULE = 'SET_CURRENT_MODULE';
export const SET_USER_QUESTIONS = 'SET_USER_QUESTIONS';
export const SET_CURRENT_RESULT_MODULE = 'SET_CURRENT_RESULT_MODULE';
export const SET_CURRENT_ITEM = 'SET_CURRENT_ITEM';
export const SET_RESULT_MODULES = 'SET_RESULT_MODULES';
export const SET_QUIZ_ENDED = 'SET_QUIZ_ENDED';
export const SET_MODULES_DATA_INITIAL = 'SET_MODULES_DATA_INITIAL';
export const UPDATE_USER_MODULES = 'UPDATE_USER_MODULES';
export const UPDATE_CURRENT_QUESTION = 'UPDATE_CURRENT_QUESTION';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const SET_CORRECT_QUESTIONS = 'SET_CORRECT_QUESTIONS';
export const SET_CURRENT_QUESTION_TXT = 'SET_CURRENT_QUESTION_TXT';
export const SET_WRONG_QUESTIONS = 'SET_WRONG_QUESTIONS';

export const API_START = 'API_START';
export const API_END = 'API_END';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const API_ERROR = 'API_ERROR';
