import React from 'react';
import { FormattedMessage } from 'react-intl';
import './questions.less';

const Questions = props => {
  const {
    question,
    answers,
    setAnswer,
    selectedAnswer,
    currentQuestion,
  } = props;
  return (
    <React.Fragment>
      <div className="ax-question-container">
        <h2 className="ax-question-title">
          <FormattedMessage
            id="quiz.question"
            defaultMessage="Frage {num}"
            values={{ num: currentQuestion }}
          />
        </h2>
        <p className="ax-question-body">{question}</p>
      </div>
      <div className="row ax-button-align">
        {answers.map(answer => (
          <button
            key={Object.keys(answer)[0]}
            type="submit"
            onClick={setAnswer.bind(this, Object.keys(answer)[0])}
            className={`ax-quiz-item ax-module-item ${
              selectedAnswer === Object.keys(answer)[0] ? 'active disabled' : ''
            }`}
          >
            {Object.values(answer)}
          </button>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Questions;
