import {
  SET_USER_MODULES,
  SET_USER_QUESTIONS,
  SET_CURRENT_MODULE,
  SET_CURRENT_RESULT_MODULE,
  SET_QUIZ_ENDED,
  SET_CURRENT_ITEM,
  SET_RESULT_MODULES,
  SET_MODULES_DATA_INITIAL,
  UPDATE_USER_MODULES,
  UPDATE_CURRENT_QUESTION,
  SET_CURRENT_QUESTION,
  SET_CORRECT_QUESTIONS,
  SET_CURRENT_QUESTION_TXT,
  SET_WRONG_QUESTIONS,
} from './actionTypes';
import { getUserModules } from '../services/user.service';
import { setUserLanguage } from './userActions';
import { apiStart, apiError } from './api';
// import fakeModulesList from '../fakeData';

export const setUserModules = listModules => ({
  type: SET_USER_MODULES,
  payload: listModules,
});

export const setWrongQuestions = wrongQ => ({
  type: SET_WRONG_QUESTIONS,
  payload: wrongQ,
});

export const setUserQuestions = material => ({
  type: SET_USER_QUESTIONS,
  payload: material,
});

export const setCurrentModule = module => ({
  type: SET_CURRENT_MODULE,
  payload: module,
});

export const setCurrentQuestion = question => ({
  type: SET_CURRENT_QUESTION,
  payload: question,
});

export const setCorrectQuestions = questionNr => ({
  type: SET_CORRECT_QUESTIONS,
  payload: questionNr,
});

export const setCurrentQuestionTxt = question => ({
  type: SET_CURRENT_QUESTION_TXT,
  payload: question,
});

// ------------------------------------------ under this i haven't worked

export const updateModules = listModules => ({
  type: UPDATE_USER_MODULES,
  payload: listModules,
});

export const setCurrentResultModule = module => ({
  type: SET_CURRENT_RESULT_MODULE,
  payload: module,
});

// export const setInitialCurrentResultModule = currentModule => {
//   console.log(currentModule);
//
//   return {
//     modules: currentModule,
//     currentChapter: [],
//     questions: [],
//   };
// };

export const fetchUserModules = (employeeId, langCode) => dispatch => {
  dispatch(apiStart());
  return new Promise(resolve => {
    getUserModules(employeeId, langCode)
      .then(({ modules }) => {
        const getModule = modules.shift();
        const qq = getModule.questions.shift();
        dispatch(setUserModules(modules));
        dispatch(setUserQuestions(getModule.questions));
        dispatch(setCurrentModule(getModule));
        dispatch(setCurrentResultModule([]));
        dispatch(setWrongQuestions([]));
        dispatch(setCurrentQuestionTxt(qq));
        dispatch(setCurrentQuestion(0));
        dispatch(setCorrectQuestions(0));
        dispatch(setUserLanguage(langCode));
        resolve(modules);
      })
      .catch(error => {
        console.log(error);
        dispatch(apiError(error));
      });
  });
};

export const setQuizEnded = () => ({
  type: SET_QUIZ_ENDED,
});

export const setCurrentItem = item => ({
  type: SET_CURRENT_ITEM,
  payload: item,
});

export const setResultModules = listModules => ({
  type: SET_RESULT_MODULES,
  payload: listModules,
});

export const setInitialModulesData = () => ({
  type: SET_MODULES_DATA_INITIAL,
});

export const updateCurrentQuestion = () => ({
  type: UPDATE_CURRENT_QUESTION,
});
