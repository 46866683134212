/* eslint-disable camelcase */
import React from 'react';
import ReactDOM from 'react-dom';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
// translation
import { IntlProvider, addLocaleData } from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';
import locale_fr from 'react-intl/locale-data/fr';
import locale_it from 'react-intl/locale-data/it';

import store from './store/store';

// components
import MainLayout from './containers/mainLayout/mainLayout';
import LoginLayout from './containers/loginLayout/loginLayout';
import FullLayout from './containers/fullLayout/fullLayout';
import NotFound from './containers/404/notfound';
import Quiz from './containers/quiz/quiz';
import ServerError from './containers/500/serverError';

import Login from './components/login/login';
import Results from './components/results/results';
import ChooseLanguage from './components/chooseLanguage/chooseLanguage';

import translation_de from './translations/de.json';
import translation_en from './translations/en.json';
import translation_fr from './translations/fr.json';
import translation_it from './translations/it.json';

import { isLoggedIn, routeEndpoint } from './services/api.service';

import './style/main.less';

addLocaleData([...locale_en, ...locale_de, ...locale_fr, ...locale_it]);

const translation = {
  de: translation_de,
  en: translation_en,
  fr: translation_fr,
  it: translation_it,
};

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const isUserLoggedIn = isLoggedIn();

  return (
    <Route
      {...rest}
      render={props =>
        isUserLoggedIn ||
        props.location.pathname === `${routeEndpoint}login` ? (
          <IntlProvider
            locale={store.getState().user.userLanguage}
            messages={translation[store.getState().user.userLanguage]}
          >
            <Layout>
              <Component {...props} />
            </Layout>
          </IntlProvider>
        ) : (
          <Redirect
            to={{
              pathname: `${routeEndpoint}login`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const App = () => (
  <Router>
    <Switch>
      <AppRoute
        exact
        path={`${routeEndpoint}`}
        layout={FullLayout}
        component={ChooseLanguage}
      />
      <AppRoute
        exact
        path={`${routeEndpoint}quiz`}
        layout={MainLayout}
        component={Quiz}
      />
      <AppRoute
        exact
        path={`${routeEndpoint}results`}
        layout={MainLayout}
        component={Results}
      />
      <AppRoute
        exact
        path={`${routeEndpoint}login`}
        layout={LoginLayout}
        component={Login}
      />
      <Route exact path={`${routeEndpoint}error`} component={ServerError} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// module.hot.accept();
