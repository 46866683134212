import React from 'react';

const LoginLayout = ({ children }) => (
  <React.Fragment>
    <div className="login-wrapper full-height full-width login-img">
      <div className="d-flex justify-content-center full-height full-width align-items-center overlay-img">
        {children}
      </div>
    </div>
  </React.Fragment>
);

export default LoginLayout;
