import React from 'react';
import logo from '../../../assets/images/logo-axpo.png';
import './serverError.less';

const ServerError = () => (
  <div className="error-page full-height full-width">
    <div className="d-flex justify-content-center full-height full-width align-items-center">
      <div className="error-container text-center">
        <img className="ax-logo" alt="Axpo Logo" src={logo} />
        <h1 className="error-number">500</h1>
        <h2 className="">Oops, something went wrong.</h2>
        <p className="ax-text">
          Try to refresh this page or feel free to contact us if the problem
          persists.
        </p>
        <a className="btn btn-primary" href="/">
          Back
        </a>
      </div>
    </div>
  </div>
);
export default ServerError;
