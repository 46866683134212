/* eslint-disable no-param-reassign */
import {
  SET_USER_MODULES,
  SET_USER_QUESTIONS,
  SET_CURRENT_MODULE,
  SET_CURRENT_RESULT_MODULE,
  SET_CURRENT_ITEM,
  SET_RESULT_MODULES,
  SET_QUIZ_ENDED,
  SET_MODULES_DATA_INITIAL,
  UPDATE_USER_MODULES,
  UPDATE_CURRENT_QUESTION,
  SET_CURRENT_QUESTION,
  SET_CORRECT_QUESTIONS,
  SET_CURRENT_QUESTION_TXT,
  SET_WRONG_QUESTIONS,
} from '../actions/actionTypes';
// import { setWrongQuestions } from '../actions/modulesActions';

const initialState = {
  modules: [],
  currentModule: null,
  resultModules: [],
  quizEnded: false,
  currentItem: null,
  currentResultModule: null,
};

export default function modules(state = initialState, action) {
  switch (action.type) {
    case SET_USER_MODULES:
      return {
        ...state,
        modules: [...action.payload],
        loading: false,
      };

    case SET_USER_QUESTIONS:
      return {
        ...state,
        questions: [...action.payload],
        loading: false,
      };

    case UPDATE_USER_MODULES:
      return {
        ...state,
        modules: action.payload,
      };

    case SET_CURRENT_QUESTION_TXT:
      return {
        ...state,
        currenQuestionTxt: action.payload,
      };

    case SET_CURRENT_MODULE:
      return {
        ...state,
        currentModule: action.payload,
      };

    case SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.payload,
      };

    case SET_CORRECT_QUESTIONS:
      return {
        ...state,
        correctQuestions: action.payload,
      };

    case SET_CURRENT_RESULT_MODULE:
      return {
        ...state,
        currentResultModule: action.payload,
      };

    case SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.payload,
      };

    case SET_RESULT_MODULES:
      return {
        ...state,
        resultModules: action.payload,
      };
    case SET_WRONG_QUESTIONS:
      return {
        ...state,
        wrongQuestions: action.payload,
      };
    case SET_QUIZ_ENDED:
      return {
        ...state,
        quizEnded: true,
      };

    case SET_MODULES_DATA_INITIAL:
      return initialState;

    case UPDATE_CURRENT_QUESTION:
      return {
        ...state,
        currentResultModule: {
          ...state.currentResultModule,
          currentQuestion: state.currentResultModule.currentQuestion + 1,
        },
      };
    default:
      return state;
  }
}
