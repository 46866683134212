import axios from 'axios';
import decode from 'jwt-decode';

const env = process.env.NODE_ENV || 'prod';
const environment =
  env === 'prod'
    ? 'https://admin.kkb-fp-einfuehrung.ch/'
    : 'http://116.203.73.127/axpo/';

// console.log(environment);

export const routeEndpoint = env === 'dev' ? '/axpoportal/' : '/';

const isTokenExpired = token => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      return true;
    }
    return false;
  } catch (err) {
    console.log('expired token check failed!');
    return false;
  }
};

const getToken = () => {
  // Retrieves the user token from localStorage
  return localStorage.getItem('token');
};

export const isLoggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // Getting token from localstorage
  return !!token && !isTokenExpired(token); // handwaiving here
};

export const fetch = axios.create({
  baseURL: environment,
  responseType: 'json',
  responseEncoding: 'utf8',
  headers: {
    'Accept-Version': 1,
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=utf-8',
  },
});

fetch.interceptors.request.use(
  response => {
    const config = response;
    // Do something before request is sent
    if (isLoggedIn()) {
      config.headers.Authorization = `${getToken()}`;
    }

    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

fetch.interceptors.response.use(
  response => {
    // Do something with response data
    if (response.status >= 200 && response.status < 300) {
      // Success status lies between 200 to 300
      return response.data;
    }

    if (response.status >= 500 && response.status < 510) {
      // Success status lies between 500 to 510
      window.location.href = '/error';
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  },
  error => {
    console.log(error);
    // Do something with response error
    return Promise.reject(error.response.data);
  }
);
