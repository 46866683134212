import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUserData } from '../../actions/userActions';
import { fetchUserModules } from '../../actions/modulesActions';
import logo from '../../../assets/images/logo-axpo.png';
import listLanguages from '../../constants/languages';
import { routeEndpoint } from '../../services/api.service';
import './chooseLanguage.less';

class ChooseLanguage extends Component {
  constructor() {
    super();
    this.state = {
      language: '',
    };
    this.saveLanguage = this.saveLanguage.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchUserData());
  }

  setLanguage(code) {
    const { language } = this.state;
    this.setState({
      language: code === language ? '' : code,
    });
  }

  saveLanguage() {
    const { dispatch, history, userData } = this.props;
    const { language } = this.state;
    dispatch(fetchUserModules(userData.id, language)).then(() => {
      history.push(`${routeEndpoint}quiz`);
    });
  }

  render() {
    const { language } = this.state;
    return (
      <div className="ax-language-container">
        <div className="ax-language-logo">
          <img alt="Axpo Logo" src={logo} />
        </div>

        <div className="ax-language-description">
          <div className="ax-language-description-animator">
            <p className="ax-text-style">Bitte wählen Sie Ihre Sprache:</p>
            <p className="ax-text-style">Please select your language:</p>
            <p className="ax-text-style">Veuillez sélectionner votre langue:</p>
            <p className="ax-text-style">Seleziona la tua lingua:</p>
          </div>
        </div>
        <div className="row m-t-40">
          {listLanguages.map(value => {
            return (
              <div
                className={`col-lg-3 col-md-3 col-sm-12 mb-5 ${
                  value.code
                }-lang`}
                key={value.code}
              >
                <button
                  type="button"
                  onClick={this.setLanguage.bind(this, value.code)}
                  className={`ax-module-item w-100 h-100 ${
                    language === value.code ? 'active' : ''
                  } ${
                    language !== '' && language !== value.code
                      ? 'not-selected'
                      : ''
                  }`}
                >
                  {/* <div>imagehere</div> */}
                  {value.name}
                </button>
              </div>
            );
          })}
        </div>
        <button
          className={`ax-button m-t-50 float-right ${
            !language ? 'hide' : 'show'
          }`}
          type="submit"
          onClick={this.saveLanguage}
        >
          Weiter
        </button>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  userData: user.userData,
  loading: false,
});

export default connect(mapStateToProps)(ChooseLanguage);
