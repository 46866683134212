import decode from 'jwt-decode';
import { fetch } from './api.service';

const setToken = token => {
  // Saves user token to localStorage or sessionStorage
  return localStorage.setItem('token', token);
};

export const getToken = () => {
  // Retrieves the user token from localStorage
  return localStorage.getItem('token');
};

const env = process.env.NODE_ENV || 'prod';
const environment =
  env === 'prod'
    ? 'https://admin.kkb-fp-einfuehrung.ch/'
    : 'http://116.203.73.127/axpo/';

export const login = (username, password) => {
  return new Promise((resolve, reject) => {
    fetch
      .post(`${environment}user/login`, { username, password, isAdmin: false })
      .then(({ token }) => {
        if (token) {
          setToken(token);
          resolve(token);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error.message);
      });
  });
};

export const logout = () => {
  // Clear user token and profile data from localStorage
  return localStorage.clear();
};

export const getUserData = () => {
  // Using jwt-decode npm package to decode the token
  const user = decode(getToken());
  return user;
};
