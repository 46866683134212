import { API_START, API_END } from '../actions/actionTypes';

export default function api(state = {}, action) {
  // console.log('action type apiReducer.js => ', action.type);
  switch (action.type) {
    case API_START:
      return {
        ...state,
        loading: true,
      };

    case API_END:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
