import { combineReducers } from 'redux';
import api from './apiReducer';
import user from './userReducer';
import modules from './modulesReducer';

const rootReducer = combineReducers({
  api,
  user,
  modules,
});

export default rootReducer;
