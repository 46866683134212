import React, { Component } from 'react';
import {
  Player,
  Shortcut,
  BigPlayButton,
  VolumeMenuButton,
  ControlBar,
  ReplayControl,
} from 'video-react';
import './video.less';

class Video extends Component {
  constructor() {
    super();
    this.videoRef = React.createRef();
    this.newShortcuts = [
      // { keyCode: 39, handle: () => {} },
      { keyCode: 76, handle: () => {} },
    ];
  }

  componentDidMount() {
    this.videoRef.current.subscribeToStateChange(
      this.handleStateChange.bind(this)
    );
  }

  handleStateChange(watching, prevState) {
    const { hasVideoEnded } = this.props;
    if (prevState.ended !== watching.ended) {
      hasVideoEnded(watching.ended);
    }
  }

  render() {
    const { videoLink } = this.props;
    return (
      <React.Fragment>
        <div className="row video-wrapper">
          <div className="ax-video">
            <Player
              ref={this.videoRef}
              src={videoLink}
              fluid={false}
              width="100%"
              autoPlay
              aspectRatio="16:9"
              toggleFullscreen={console.log()}
            >
              <BigPlayButton position="center" />
              <Shortcut clickable={false} shortcuts={this.newShortcuts} />

              <ControlBar autoHide>
                <ReplayControl seconds={10} order={2.2} />
                <ReplayControl seconds={30} order={2.3} />
                <VolumeMenuButton vertical />
              </ControlBar>
            </Player>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Video;
