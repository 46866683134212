import React from 'react';
import { connect } from 'react-redux';
import '../../style/main.less';
import './header.less';
import logo from '../../../assets/images/logo-axpo.png';

const Header = ({ currentModule, questions, modules }) => {
  let allQuestions;

  if (currentModule) {
    if (modules.length === 0) {
      allQuestions = currentModule.questions.length;
    } else {
      allQuestions = currentModule.questions.length + 1;
    }
  }
  const currentAmountOfQuestions = questions.length;
  const difference = allQuestions - currentAmountOfQuestions;

  return (
    <div className="ax-header fixed-top">
      {/* START MOBILE SIDEBAR TOGGLE */}
      <div className="header-wrapper">
        <img className="logo-i" alt="Axpo Logo" src={logo} />
        {currentModule && currentModule.moduleName ? (
          <React.Fragment>
            <h3 className="current-module bold">{currentModule.moduleName}</h3>
            <h3 className="current-step bold">
              {difference}/{allQuestions}
            </h3>
          </React.Fragment>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ modules }) => ({
  modules: modules.modules,
  questions: modules.questions,
  currentModule: modules.currentModule,
  currentQuestion: modules.currentQuestion,
});

export default connect(mapStateToProps)(Header);
