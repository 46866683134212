import {
  SET_USER_DATA,
  SET_USER_LANGUAGE,
  SET_USER_DATA_INITIAL,
} from '../actions/actionTypes';

import initialState from './initialState';

export default function user(state = initialState, action) {
  // console.log('action type userReducer.js => ', action.type);
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
        loading: false,
      };

    case SET_USER_LANGUAGE:
      return {
        ...state,
        userLanguage: action.payload,
        loading: false,
      };

    case SET_USER_DATA_INITIAL:
      return initialState;

    default:
      return state;
  }
}
