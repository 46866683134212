import React from 'react';
import Header from '../../components/header/header';
// import Footer from '../../components/footer/footer';

import './mainLayout.less';

const MainLayout = ({ children }) => (
  <React.Fragment>
    <Header />
    <div className="justify-content">{children}</div>
    {/* <Footer /> */}
  </React.Fragment>
);

export default MainLayout;
